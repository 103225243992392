import jwt from 'jwt-decode'
import axios from 'axios'
import { usePapaParse } from 'react-papaparse'
export const base_url = () => {
  let base_url = ''

  switch (process.env.REACT_APP_STAGE) {
    case 'chris':
      base_url = process.env.REACT_APP_SERVICE_API_CHRIS
      break
    case 'production':
      base_url = process.env.REACT_APP_SERVICE_API_PRODUCTION
      break
    default:
      base_url = process.env.REACT_APP_SERVICE_API_CHRIS
      break
  }

  return base_url
}

export const hours_till = (dateString) => {
  // Parse the date string
  // Note: The format includes timezone offset, so we'll use UTC methods for consistency
  if (!dateString) return -1

  const givenDate = new Date(dateString) // Convert to ISO 8601 format and set to UTC

  // Get current date in UTC
  const currentDate = new Date()
  const currentDateUTC = new Date(currentDate.toUTCString())

  // Calculate the difference in milliseconds
  const differenceMs = givenDate - currentDateUTC
  // Convert milliseconds to days
  const hrs = Math.ceil(differenceMs / (1000 * 60 * 60))

  return hrs
}

export function decode(token) {
  return decode_token(token)
}

export const friendly_user_role = (role) => {
  switch (role) {
    case 'super':
      return 'Admin*'
    case 'owner':
      return 'Owner'
    case 'admin':
      return 'Admin'
    case 'setup':
      return 'Manager'
    case 'chat':
      return 'Chat User'
    case 'basic':
      return 'Basic User'
    case 'view_only':
      return 'View Only User'
    default:
      return 'Unknown'
  }
}
// export const friendly_disposition = (disp) => {
//   switch (true) {
//     case ['delivered', 'delivery_unconfirmed'].includes(disp):
//       return 'Delivered'
//     case ['opted_out', 'opt_out'].includes(disp):
//       return 'Opted Out'
//     case ['failed', 'delivery_failed'].includes(disp):
//       return 'Failed'
//     default:
//       return ''
//   }
// }

export const disposition_groups = () => {
  const unsent = ['inactive', 'reserved']
  const sent = ['queued', 'processing_send', 'clicked', 'sent', 'locked']
  const failed = ['failed', 'delivery_failed', 'unsent']
  const delivered = ['delivered', 'delivery_unconfirmed']
  const spam = ['spam']
  const opt_out = ['opted_out', 'opt_out']

  return {
    unsent,
    sent,
    failed,
    delivered,
    spam,
    opt_out,
  }
}
export const get_friendly_disposition = (state) => {
  const { unsent, sent, failed, delivered, spam, opt_out } = disposition_groups()

  switch (true) {
    case unsent.includes(state):
      return 'Unsent'
    case sent.includes(state):
      return 'Sent'
    case failed.includes(state):
      return 'Failed'
    case delivered.includes(state):
      return 'Delivered'
    case spam.includes(state):
      return 'Spam'
    case opt_out.includes(state):
      return 'Opt Out'
    default:
      return state
  }
}

export const remap_data = ({
  ary = [],
  selected_value = '',
  selected_values = [],
  remap = {},
  group_by_att,
  att_mask,
}) => {
  if (group_by_att != undefined) {
    const unique_grouped_atts = Array.from(new Set(ary.map((item) => item[group_by_att])))

    return unique_grouped_atts.map((att) => {
      return {
        label: att_mask ? att_mask(att) : att,
        selectAll: true,
        options: ary
          .filter((item) => item[group_by_att] === att)
          .map((item) => {
            const obj = { ...item }
            Object.keys(remap).map((remap_key) => {
              const value = item[remap[remap_key]]
              obj[remap_key] = value
              if (selected_values.includes(value)) {
                obj.selected = true
              }
              if (selected_value === value) {
                obj.selected = true
              }
            })

            return obj
          }),
      }
    })
  }
  return ary.map((item) => {
    const obj = { ...item }
    Object.keys(remap).map((remap_key) => {
      const value = item[remap[remap_key]]
      obj[remap_key] = value
      if (selected_value === value) {
        obj.selected = true
      }
    })

    return obj
  })
}

export const decode_token = (token = null) => {
  if (token === null || Object.keys(token).length === 0) return {}
  try {
    return jwt(token)
  } catch (err) {
    console.error('Error in decoding Token', { err, typeOf: typeof token, token })
    return {}
  }
}

export const get_badge = (status = '') => {
  switch (status.toLowerCase()) {
    case 'awaiting_activation':
      return 'warning'
    case 'errored':
      return 'danger'
    case 'archived':
      return 'dark'
    case 'active':
      return 'success'
    case 'inactive':
      return 'warning'
    case 'ready':
      return 'primary'
    case 'unconfigured':
      return 'secondary'
    case 'pending':
      return 'warning'
    case 'banned':
      return 'danger'
    default:
      return 'primary'
  }
}

export const is_authed = ({ brand_id, role, token }) => {
  const de_token = JSON.parse(token)
  // if brand_id is  in the decoded token, return true
  if (de_token.brands.map((b) => b.brand_id).includes(brand_id)) return true
  return false
}
export const role_required = (role) => {
  const de_token = decode_token(localStorage.token)

  const access_lvls = ['super', 'owner', 'admin', 'setup', 'chat', 'basic', 'view_only']

  // if role provided is either equal to or higher than the role in the token, return true
  if (access_lvls.indexOf(de_token.role) <= access_lvls.indexOf(role)) return true
  return false
}

export const snake_human_text = (str) => {
  return str
    .split('_')
    .map((word) =>
      word
        .replace(/,/g, '_') // replace commas with underscores
        .split(' ')
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        .join(' '),
    )
    .join(' ')
}

export const authed_brand_ids = (token) => {
  // const de_token = decode_token(token)
  const de_token = JSON.parse(token)
  return de_token.brands.map((b) => b.brand_id)
}

export function unix_time() {
  return Math.floor(Date.now() / 1000)
}
export const ary_to_labels = ({ items, label_key, value_key }) => {
  return items.map((item) => {
    return {
      label: `${item[label_key]}`,
      value: `${item[value_key]}`,
    }
  })
}

export function call_api({ method = 'post', endpoint = '/', payload = {} }) {
  let base_url = ''

  switch (process.env.REACT_APP_STAGE) {
    case 'chris':
      base_url = process.env.REACT_APP_SERVICE_API_CHRIS
      break

    case 'production':
      base_url = process.env.REACT_APP_SERVICE_API_PRODUCTION
      break

    default:
      console.error('process.env.REACT_APP_STAGE not defined, defaulting to Chris')
      base_url = process.env.REACT_APP_SERVICE_API_CHRIS
      break
  }
  const config = {
    header: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }

  return axios({
    method,
    url: `${base_url}${endpoint}`,
    data: payload,
    header: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },
  })
}

export function brand_by_id({ brands, brand_id }) {
  const { items = null } = brands
  if (items === null) return {}

  const output = items.filter((brand) => brand.brand_id === brand_id).shift()

  if (output === undefined) return {}
  return output
}

export function sort_by(array, prop) {
  if (!Array.isArray(array)) {
    throw new Error('Input must be an array')
  }

  return array.sort((a, b) => {
    if (a[prop] < b[prop]) {
      return -1
    }
    if (a[prop] > b[prop]) {
      return 1
    }
    return 0
  })
}

export function calc_message_char_count(msgCount) {
  // return msgCount === 1 ? 160 : msgCount * 153;

  const sms_char_length_array = [160, 306, 459, 612, 763, 918, 1071, 1224, 1377, 1530]

  if (msgCount >= 10) return 'Too Many'

  return sms_char_length_array[msgCount - 1] || sms_char_length_array[0]
}

export function calc_message_count(inputLength) {
  let msg_parts = 1
  switch (true) {
    case inputLength >= 0 && inputLength <= 160:
      msg_parts = 1
      break
    case inputLength > 160 && inputLength <= 306:
      msg_parts = 2
      break
    case inputLength > 306 && inputLength <= 459:
      msg_parts = 3
      break
    case inputLength > 459 && inputLength <= 612:
      msg_parts = 4
      break
    case inputLength > 612 && inputLength <= 763:
      msg_parts = 5
      break
    case inputLength > 763 && inputLength <= 918:
      msg_parts = 6
      break
    case inputLength > 918 && inputLength <= 1071:
      msg_parts = 7
      break
    case inputLength > 1071 && inputLength <= 1224:
      msg_parts = 8
      break
    case inputLength > 1224 && inputLength <= 1377:
      msg_parts = 9
      break
    case inputLength > 1377 && inputLength <= 1530:
      msg_parts = 10
      break
    case inputLength > 1530 && inputLength <= 1683:
      msg_parts = 11
      break
    case inputLength > 1683 && inputLength <= 1836:
      msg_parts = 12
      break
    case inputLength > 1836 && inputLength <= 1989:
      msg_parts = 13
      break
    case inputLength > 1989 && inputLength <= 2142:
      msg_parts = 14
      break
    case inputLength > 2142 && inputLength <= 2295:
      msg_parts = 15
      break
    case inputLength > 2295 && inputLength <= 2448:
      msg_parts = 16
      break
    case inputLength > 2448 && inputLength <= 2601:
      msg_parts = 17
      break
    case inputLength > 2601 && inputLength <= 2754:
      msg_parts = 18
      break
    case inputLength > 2754 && inputLength <= 2907:
      msg_parts = 19
      break
    case inputLength > 2907 && inputLength <= 3060:
      msg_parts = 20
      break

    default:
      console.error('Error calculating msg parts.')
      // alert('Message length too long.');
      msg_parts = -1
      break
  }
  return msg_parts
}

export function disp_e164(e164) {
  if (e164 === '') return ''
  // Extract country code assuming it's 1 digit for North American numbers
  let country_code = e164.substring(0, 2) // '+1'

  // Extract the next 10 digits
  let main_number = e164.substring(2)

  // Format the main number into (XXX) XXX-XXXX
  let formatted_main_number =
    '(' +
    main_number.substring(0, 3) +
    ') ' +
    main_number.substring(3, 6) +
    '-' +
    main_number.substring(6)

  return country_code + ' ' + formatted_main_number
}
export function ary_of_obj_to_obj(ary, key_col) {
  let obj = {}
  ary.forEach((item) => {
    obj[item[key_col]] = item
  })
  return obj
}
export async function handle_api_call({
  dispatch,
  on_success = () => {},
  on_fail = (err) => {
    console.log(err)
    dispatch({
      type: 'CREATE_TOAST',
      code: 500.01,
    })
  },
  payload = {},
  method = 'GET',
  endpoint = `/campaigns`,
}) {
  const url = `${base_url()}${endpoint}`

  const req_headers = new Headers()

  req_headers.append('Content-Type', 'application/json')
  req_headers.append('Authorization', `Bearer ${localStorage.token}`)
  console.log({ url, method, req_headers, payload })
  fetch(url, {
    method: method,
    headers: req_headers,
    body: ['get'].includes(method.toLocaleLowerCase()) ? undefined : JSON.stringify(payload),
  })
    .then(async (response) => await response.json())
    .then(async (data) => await on_success(data))
    .catch((e) => on_fail(e))
}

export function clean_number(phone = '') {
  if (phone === null) return null

  const stripedPhone = phone.replace(/\D/g, '')
  if (phone.indexOf('+1') === 0) return '+' + stripedPhone
  if (stripedPhone.indexOf('1') === 0 && stripedPhone.length === 11) return '+' + stripedPhone

  if (!phone || stripedPhone.length !== 10) return null
  return '+1' + phone.replace(/\D/g, '')
}

export function mask_e164(dirty_e164) {
  const e164 = clean_number(dirty_e164)
  return e164.replace(/(\d{1})(\d{3})(\d{3})(\d{2})/, '$1 ($2) $3-$4')
}

export function download_obj_as_csv({ data, filename = 'data.csv', cols }) {
  // Convert array of objects to CSV string

  const { jsonToCSV } = usePapaParse()

  // remove all keys that start with "brand_cost__"
  //  flatten array of objects and make an array of all keys not starting with "brand_cost__"

  // .map((entry) =>
  //   Object.fromEntries(
  //     Object.entries(entry).map(([key, value]) => [snake_human_text(key), value]),
  //   ),
  // )

  const filtered_data = data.map((entry) =>
    Object.fromEntries(Object.entries(entry).map(([key, value]) => [snake_human_text(key), value])),
  )
  const filtered_keys = cols.map((entry) => snake_human_text(entry))

  const csv_data = jsonToCSV(filtered_data, {
    header: true, // Include object keys as the first row
    columns: filtered_keys,
    delimiter: ',', // Default delimiter
  })

  // Create a Blob from the CSV string
  const blob = new Blob([csv_data], { type: 'text/csv;charset=utf-8;' })

  // Create a temporary anchor element for downloading
  if (window.navigator.msSaveOrOpenBlob) {
    // For IE and Edge
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // Set the HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export function human_readable_phone(e164) {
  // Remove the '+' at the start if it exists
  let cleaned = ('' + e164).replace(/\D/g, '')

  // Check if the number is at least 10 digits long
  if (cleaned.length < 10) {
    throw new Error('Invalid phone number length')
  }

  // US number should have 11 digits with country code, but we'll handle it either way
  if (cleaned.length > 10) {
    cleaned = cleaned.substr(cleaned.length - 10) // Take only last 10 digits
  }

  // Format the number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  } else {
    throw new Error('Phone number format not recognized')
  }
}
