const codes = {
  // 100 Interactions initiated by the user
  100.0: {
    title: 'Loading...',
    msg: 'Please wait.',
    category: 'loading',
  },
  100.1: {
    title: 'Updating...',
    msg: '',
    category: 'loading',
  },
  100.2: {
    title: 'Project ID',
    msg: 'Coppied to Clipboard!',
    category: 'success',
  },
  // 200 Successfull Actions
  200.01: {
    title: 'Created.',
    msg: 'Great Job!',
    category: 'success',
  },
  201.1: {
    title: 'Created User.',
    msg: "You've expanded your team!",
    category: 'success',
  },
  202.1: {
    title: 'Created Brand.',
    msg: 'Next up, create users, campains, and projects!',
    category: 'success',
  },
  203.1: {
    title: 'Created Project.',
    msg: 'Configure your project to get started.',
    category: 'success',
  },
  201.11: {
    title: 'Update User Success!',
    msg: 'Changes are be in place now.',
    category: 'success',
  },
  202.11: {
    title: 'Update Brand Success!',
    msg: 'Changes are be in place now.',
    category: 'success',
  },
  203.11: {
    title: 'Update Project Success!',
    msg: 'Changes are be in place now.',
    category: 'success',
  },
  201.12: {
    title: 'Disabled User.',
    msg: 'They will be unable to access ANY of the brands they are associated with.',
    category: 'success',
  },
  202.12: {
    title: 'Deleted Brand.',
    msg: 'Brand has been Deleted.',
    category: 'success',
  },
  209.12: {
    title: 'Disabled Campaign.',
    msg: 'Access to this Campaign is now restricted.',
    category: 'success',
  },
  204.11: {
    title: 'Uploaded File.',
    msg: 'Processing now...',
    category: 'success',
  },
  205.11: {
    title: 'Project Archived.',
    msg: 'You can still access the project archive, if needed.',
    category: 'success',
  },
  210.0: {
    title: 'Generating Report.',
    msg: 'Report will be available shortly in the Reports section.',
    category: 'info',
  },
  220.0: {
    title: 'Brand Updated.',
    msg: 'Pricing changes are for future charges.',
    category: 'success',
  },
  // 400 Access Errors
  400.01: {
    msg: 'Access Denied.',
    category: 'warning',
  },
  409.11: {
    msg: 'Project was not in a "ready" state to activate.',
    category: 'error',
  },
  409.12: {
    msg: 'Unable to upload, balance is insufficient.',
    category: 'error',
  },
  // 500 Server Errors
  500.01: {
    msg: 'Not able to connect to the server. If this problem persists, please contact support.',
    category: 'error',
  },
  500.21: {
    msg: 'Error Tagging conversation.',
    category: 'error',
  },
  523.01: {
    msg: 'No Available Phone Numbers Found.',
    category: 'error',
  },
}
export default codes
