import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CSpinner, useColorModes } from '@coreui/react-pro'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const PreAuthLayout = React.lazy(() => import('./layout/PreAuthLayout'))
const AuthLanding = React.lazy(() => import('./views/auth/Landing'))
const TOS = React.lazy(() => import('./views/auth/TOS'))
const RegisterBrand = React.lazy(() => import('./views/auth/RegisterBrand'))

// Pages
// const Login = React.lazy(() => import('./core_views/pages/login/Login'))
// const Register = React.lazy(() => import('./core_views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./core_views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./core_views/pages/page500/Page500'))

// Email App
// const EmailApp = React.lazy(() => import('./core_views/apps/email/EmailApp'))

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('theme-default')
  const storedTheme = useSelector((state) => state.theme)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }
    console.log('storedTheme', storedTheme)
    setColorMode(storedTheme)
  }, []) // eslint-disable-line

  return (
    <HashRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          {/* <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
          {/* <Route path="/apps/email/*" name="Email App" element={<EmailApp />} /> */}
          <Route path="/auth/login" name="Login" element={<AuthLanding />} />
          <Route path="/auth/tos" name="Terms of Service" element={<TOS />} />
          <Route path="/auth/register_brand" name="Login" element={<AuthLanding />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
